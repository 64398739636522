import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from '../sections/customer-stories/Testimonial'
import Hero from "~sections/project/Integration/Hero/Hero"
import Zapier from "~sections/project/Integration/Sections/Zapier"
import GradTwo from "~sections/project/Integration/Sections/grad"
import ReqSec from "~sections/project/Integration/Sections/req"
import { Helmet } from "react-helmet";

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Integrations() {
  return (
    <>
    <Helmet>
        <title>We-Connect Integrations</title>
        <meta name="description" content="We-Connect integrates seamlessly with your existing tech stack through native CRM integrations & APIs. Explore our integrations here."/>
        <meta property="og:title" content="We-Connect Integrations "/>
        <meta property="og:description" content="We-Connect integrates seamlessly with your existing tech stack through native CRM integrations & APIs. Explore our integrations here.  "/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/integrations"/>

        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect Integrations "/>
        <meta property="twitter:description" content="We-Connect integrates seamlessly with your existing tech stack through native CRM integrations & APIs. Explore our integrations here. "/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect-integrations.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <Hero />
      <Zapier />
      <ReqSec />
      <GradTwo />
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
