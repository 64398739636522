import styled from "styled-components/macro"
import { Link } from "~components"
import { Box, Heading, Button, Paragraph } from "~styled"

const Content = styled(Box)`
  padding-top: 75px;
  padding-bottom: 60px;
  border-bottom: 0px solid rgba(128, 138, 142, 0.2);
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 135px;
    padding-bottom: 135px;
  }
  .nav-link{
    color: #000 !important;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 1.20689;
    margin-bottom: 32px;
    @media (min-width: 480px) {
      font-size: 28px;
    }

    @media (min-width: 768px) {
      font-size: 34px;
    }
    @media (min-width: 992px) {
      font-size: 38px;
    }
    @media (min-width: 1200px) {
      font-size: 40px;
    }
    @media (min-width: 1400px) {
      font-size: 44px;
    } 
  }
  .nav-link.active{
    color: #5338fc !important;
    font-weight: 500;
    border-bottom-color: white;
  }
`
Content.Block = styled(Box)`
  border-top: 1px solid rgba(128, 138, 142, 0.2);
  padding-top: 60px;
  padding-bottom: 60px;
  

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 132px;
    padding-bottom: 135px;
  }
  
`

Content.Block = styled(Box)`
  /* padding-top: 80px; */
  @media (min-width: 768px) {
    padding-top: 80px;
  }
`
Content.Title = styled(Heading)`
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

  @media (min-width: 480px) {
    font-size: 38px;
  }

  @media (min-width: 768px) {
    font-size: 48px;
  }

  @media (min-width: 992px) {
    font-size: 54px;
  }
`
Content.TitleSmall = styled(Heading)
`
font-size: 24px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 28px;
}
`
Content.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 40px;
`
Content.SubText = styled(Paragraph)`
font-size: 25px;
font-weight: 500;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 32px;
@media (min-width: 480px) {
  font-size: 28px;
}

@media (min-width: 768px) {
  font-size: 34px;
}
@media (min-width: 992px) {
  font-size: 38px;
}
@media (min-width: 1200px) {
  font-size: 40px;
}
@media (min-width: 1400px) {
  font-size: 44px;
}
`
Content.ToolsText = styled(Paragraph)`
font-size: 14px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;
margin-left: 10px;
margin-right:10px;
`
Content.SubTitle = styled(Heading)
`
font-size: 21px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 16px;
}
`
Content.ToolsTitle = styled(Heading)
`
font-size: 18px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
padding: 0px 0;
@media (min-width: 992px){
    margin-top: auto;
    margin-bottom: auto;
}
`
Content.ClrText = styled(Paragraph)`
font-size: 25px;
font-weight: 500;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 32px;
color: #5338fc;
@media (min-width: 480px) {
  font-size: 28px;
}

@media (min-width: 768px) {
  font-size: 38px;
}

@media (min-width: 992px) {
  font-size: 44px;
}
`
Content.Button = styled(Link)`
border-radius: 10px;
padding: 16px 35px;
margin-top: 40px;
min-width:100%;

@media (min-width:1400px){
        min-width:137px;
        right: 10px;
        transform: translateY(-50%);
        top: 50%;
    }
`

Content.Image = styled(Box)`
position: relative;
margin-bottom: 40px;
width: auto;
height: auto;
@media (min-width: 992px){
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    width: 348px;
    height: 348px;
}
@media (min-width: 1200px){
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 516px;
  height: 400px;
}
`
Content.Logo = styled(Box)`
position: relative;
margin-left: 10px;
margin-bottom: 10px;
width: 50px;
height: 50px;
@media (min-width: 992px){
    margin-left: 10px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
}
@media (min-width: 1600px){
  margin-left: 10px;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
`
Content.Zap = styled(Box)`
position: relative;
margin-bottom: 10px;
margin-left: 10px;
width:85px;
height: 40px;
`
Content.Fill = styled(Box)`
@media (min-width: 992px){
  margin-left: 40px;

}
@media (min-width: 1200px){
  margin-left: 60px;

}
@media (min-width: 1400px){
  margin-left: 80px;

}
`

Content.ContentShape = styled(Box)`
    width: 38%;
    position: absolute;
    left: -1%;
    top: -7%;
    z-index: -1;
`

Content.Box = styled(Box)``
Content.TextBlock=styled(Box)``

export default Content