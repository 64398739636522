import styled from "styled-components/macro"
import { Link } from "~components"
import { Box, Heading, Button, Paragraph } from "~styled"

const Content = styled(Box)`
  padding-top: 75px;
  padding-bottom: 60px;
  border-bottom: 0px solid rgba(128, 138, 142, 0.2);
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 165px;
    padding-bottom: 135px;
  }
`
Content.Block = styled(Box)`
  border-top: 1px solid rgba(128, 138, 142, 0.2);
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 132px;
    padding-bottom: 135px;
  }
`

Content.Block = styled(Box)`
  /* padding-top: 80px; */ 
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 10px;
  background-color: #F3F8FE;
  
`
Content.Title = styled(Heading)`
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

  @media (min-width: 480px) {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    font-size: 34px;
  }

  @media (min-width: 992px) {
    font-size: 40px;
  }
`
Content.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`

Content.Image = styled(Box)`
  position: relative;
  margin-bottom: 40px;
  z-index: 1;

  @media (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 55px;
  }
`
Content.ContentShape = styled(Box)`
    width: 38%;
    position: absolute;
    left: -1%;
    top: -7%;
    z-index: -1;
`
Content.Button = styled(Link)`
border-radius: 10px;
padding: 16px 35px;
margin-top: 40px;
min-width:100%;

@media (min-width:1200px){
        min-width:137px;
        right: 10px;
        transform: translateY(-50%);
        top: 50%;
    }
`


Content.Box = styled(Box)``

export default Content