import React, { useState } from 'react'
import Content from '../Styles/styleOne'
import { Link, SuperTag } from '~components';
import { Col, Container, Row, Nav, Tab } from 'react-bootstrap';
import { TfiAngleRight } from "react-icons/tfi";
import img from "~assets/image/media/Hubspot/zapier.svg";
import imgTwo from "~assets/image/media/Hubspot/make.svg";
import imgThree from "~assets/image/media/Hubspot/hubspot-icon.svg";
import imgFour from "~assets/image/media/Hubspot/zoho.svg";
import imgFive from "~assets/image/media/Hubspot/pipedrive.svg";
import imgSix from "~assets/image/media/Hubspot/salesforce.svg";

export default function Zapier({}){
  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
    padding: '20px',
    paddingBottom: '35px',
    marginBottom: '10px',
};
const crm = {
  border: '1px solid rgba(0, 0, 0, 0.05)',
  borderRadius: '5px',
  padding: '35px',
  paddingBottom: '35px',
  marginBottom: '10px',
};
const zapier = {
border: '1px solid rgba(0, 0, 0, 0.05)',
borderRadius: '5px',
padding: '10px',
marginBottom: '10px',
};
return(
<Content>
  <Container>
<Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
    <Content.Box>
    <Nav variant="underline" className="flex-column mb-5">
            <Nav.Item className='underline'>
            <Nav.Link eventKey="first"> <SuperTag value="Native Integrations"/> <TfiAngleRight fontSize={28} /></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Automation <TfiAngleRight fontSize={28}/> </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third"> <SuperTag value="Developer Tools"/> <TfiAngleRight fontSize={28} /></Nav.Link>
            </Nav.Item>
          </Nav>
    </Content.Box>
    </Col>
    <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 ml-5">
        <Content.Fill>
        <Tab.Content>
        <Tab.Pane eventKey="first">
        <Row className="align-items-center justify-content-center">
        <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col className='col-xl-4'><Content.Logo><img className="w-100" src={imgThree} alt="icon" layout="fullWidth" /></Content.Logo></Col>
        <Col className='col-xl-8'><Content.ToolsTitle> <SuperTag value="Hubspot"/> <i class="fa fa-check-circle" style={{color: '#13a451'}}></i></Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Share data between We-Connect and HubSpot in real time to streamline contact management and campaign reporting."/> </Content.ToolsText>
      <Content.ToolsText className='d-flex justify-content-end'><Link to='/hubspot-integration'>Learn more</Link></Content.ToolsText>
      </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col><img className="w-50 mb-3" src={imgSix} alt="icon" layout="fullWidth" /></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Salesforce"/> </Content.ToolsTitle></Col>
        </Row>
        <Content.ToolsText className="pl-3"> <SuperTag value="Sync contact and campaign data between We-Connect and Salesforce to align all marketing outreach."/> </Content.ToolsText>
        <Content.ToolsText className='d-flex justify-content-end'><Link to='/salesforce-integration'>Learn more</Link></Content.ToolsText>
      </Content.TextBlock>
      </Col>
      </Row>
      <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={crm}>
        <Row>
        <Col><img className="w-50 mb-5" src={imgFour} alt="icon" layout="fullWidth" /></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Zoho CRM"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Coming soon!"/> </Content.ToolsText>
      </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={crm}>
        <Row>
        <Col ><img className="w-100 mb-3" src={imgFive} alt="icon" layout="fullWidth" /></Col>
        <Col ><Content.ToolsTitle> <SuperTag value="Pipedrive"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Coming soon!"/> </Content.ToolsText>
      </Content.TextBlock>
      </Col>
      
    </Row>
          
        </Tab.Pane>

        <Tab.Pane eventKey="second"><Content.Text> <SuperTag value="Integrating various tools and platform is crucial for streamlining workflow and boosting productivity. Discover how We-Connect integration can revoluntionize your processes, saving you time and effort while maximizing results."/> 
            </Content.Text>
      <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col><Content.Zap><img className="w-100" src={img} alt="icon" layout="fullWidth" /></Content.Zap></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Zapier"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Integrate We-Connect with Zapier."/> </Content.ToolsText>
      <Content.ToolsText><Link to='https://zapier.com/app/login?next=/developer/public-invite/149418/1e64204a69a34294a6b544c20f6bc625/' target="_blank">Connect <i class="bi bi-box-arrow-up-right"></i></Link></Content.ToolsText>
      </Content.TextBlock>
      
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={styles}>
        <Row>
        <Col><img className="w-100 mb-5" src={imgTwo} alt="icon" layout="fullWidth" /></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Make"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Coming soon!"/> </Content.ToolsText>
      </Content.TextBlock>
      
      </Col>
      </Row>
      </Tab.Pane>

          <Tab.Pane eventKey="third"><Content.SubText as="h2"> <SuperTag value="API"/> </Content.SubText>
          <Content.Text> <SuperTag value="Integrating various tools and platform is crucial for streamlining workflow and boosting productivity. Discover how We-Connect integration can revoluntionize your processes, saving you time and effort while maximizing results."/> </Content.Text>
          <Content.Button  className="btn btn-primary text-white" target="_blank" to="//developers.we-connect.io/">Access API documentation and resources for developers</Content.Button></Tab.Pane>
          </Tab.Content>
          
        </Content.Fill>
      </Col>
    </Row>
    </Content.Block>
  </Tab.Container>
  </Container>
</Content>
)
}