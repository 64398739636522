import React from 'react'
import Content from '../Styles/styleGrad'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';

export default function GradTwo({}){

return(
<Content className='grad'>
  
  <Container>
    <Content.Block>
    <Row className="justify-content-center text-center">
    <Col xs="12">
    <Content.Title as="h2"> <SuperTag value="Experience Efficiency with We-Connect"/> </Content.Title>
    <Content.Button className="btn btn-white" target="_blank" to="//account.we-connect.io/signup">Start a 14 day free trial</Content.Button>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}