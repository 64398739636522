import React from 'react'
import Content from '../Styles/styleTwo'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';

export default function ReqSec({}){

return(
<Content >
  
  <Container>
    
    <Row className="justify-content-center text-center">
    <Col className="col-xl-12 col-lg-12 col-md-12 col-xs-10" xs="12" >
    <Content.Block>
    <Content.Title as="h2"> <SuperTag value="Have a specific integration in mind?"/> </Content.Title>
    <Content.Text > <SuperTag value="Whether it's a new CRM, social media platform, or productivity tool, we're committed to enhancing your experience with We-Connect."/> </Content.Text>
    <Content.Button className="btn btn-outline-black" target="_blank" to="//feedback.we-connect.io/b/50758qv1/feature-ideas">Request an Integration</Content.Button>
    </Content.Block>
      </Col>
    </Row>
    

    
  </Container>
</Content>
)
}